@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: quicksand, Arial, Helvetica;
  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sw-theme-arrows>.nav .nav-link.inactive {
  color: #999;
  border-color: #f8f8f8 !important;
  background: #f8f8f8 !important;
  cursor: not-allowed;
}

.sw-theme-arrows>.nav .nav-link.active {
  color: #fff;
  border-color: #002800 !important;
  background: #002800 !important;
  cursor: pointer;
}



.sw-theme-arrows>.nav .nav-link.active::after {
  border-left-color: #002800 !important;
}

.sw-theme-arrows>.nav .nav-link.done::after {
  border-left-color: #f8f8f8 !important;
}

.sw-theme-arrows .toolbar>.btn {
  color: #fff;
  background-color: #002800;
  border: 1px solid #002800 !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 400;
}

.tab-content {
  height: auto !important;
}

.sw-theme-arrows>.nav .nav-link {
  padding: 0.4rem;
  line-height: 1.1;
}

/* multistep  */

.ant-steps {
  margin-bottom: 10px;
}

.ant-steps-item-icon .ant-steps-icon {
  top: -1.5px !important;
}

.ant-steps-item-title {
  font-size: 14px !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #e1952a !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #e1952a !important;
}

.ant-steps-vertical {
  display: none !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
}

.country-select {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .steps-action {
    justify-content: space-between !important;
  }
}

@media print {

  /* All your print styles go here */
  /* *:not(.print_content > *) { display: none !important; }  */
  .no-print * {
    display: none !important;
  }

  .main {
    width: 100% !important;
  }

  .print_content {
    display: block !important;
    border-color: #999 !important;
    border-width: 1 !important;
    max-height: 900px;
  }

  .court-of-arm-label {
    margin-top: 2px !important;
  }

  .court-of-arm img {
    width: 39% !important;
    margin-bottom: 3px;
  }

  .court-of-arm>div {
    width: 115px !important;
  }

  .court-of-arm span {
    font-size: 6px !important;
    line-height: 6px !important;
    text-align: center;
  }

  .court-of-arm .ministry-name {
    font-size: 7px !important;
    margin-top: 2px;
    text-align: center;
  }

  table.bordered-table td {
    border-color: #888 !important;
    border-width: 1px !important;
  }

  @page {
    size: A4 landscape !important;
    margin: 10px !important;
  }


}

.title-case {
  text-transform: capitalize;
}

/* since nested groupes are not supported we have to use 
     regular css for the nested dropdowns 
  */
li>ul {
  transform: translatex(100%) scale(0)
}

li:hover>ul {
  transform: translatex(101%) scale(1)
}

li>button svg {
  transform: rotate(-90deg)
}

li:hover>button svg {
  transform: rotate(-270deg)
}

/* Below styles fake what can be achieved with the tailwind config
     you need to add the group-hover variant to scale and define your custom
     min width style.
  	 See https://codesandbox.io/s/tailwindcss-multilevel-dropdown-y91j7?file=/index.html
  	 for implementation with config file
  */
.group:hover .group-hover\:scale-100 {
  transform: scale(1)
}

.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg)
}

.scale-0 {
  transform: scale(0)
}

.min-w-32 {
  min-width: 8rem
}

.react-tel-input {
  margin-top: 4px !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 85%;
  width: 100%;
  position: absolute;
}

.video-responsive .video-title {
  background-color: #002800;
  color: white;
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  z-index: 10;
}

.legend-item {
  width: 10px;
  height: 10px;
}

.bg_pie_one {
  background-color: #ED8682;
}

.bg_pie_two {
  background-color: #75EACB;
}

.bg_pie_three {
  background-color: #82AADD;
}

.bg_pie_four {
  background-color: #43425D;
}

.bg_pie_five {
  background-color: #E2FCE0;
}

.bg_pie_six {
  background-color: #9CA3AF;
}

.bg_pie_seven {
  background-color: #5A607F;
}

.bg_pie_eight {
  background-color: #FEE2E2;
}

.bg_pie_nine {
  background-color: #FFA500;
}

.hide-div {}

.page-break {}

.margin-top {}

.row-layout {}

.hide-table {
  display: none;
}

.pie-padding {}

.header-text-bold {}

.table-padding {}

.chart-padding {}

.print-header {
  display: none;
}

.print-header-img {
  display: none;
}

@media print {
  .hide-div {
    display: none !important;
  }

  .page-break {
    page-break-after: always;
  }

  .page-break-before {
    page-break-before: always;
  }

  .page-break-inside {
    page-break-inside: avoid !important;
  }

  .page-fix {
    page-break-inside: avoid !important;
  }

  .margin-top {
    margin-top: 100px !important;
  }

  .row-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .hide-table {
    display: block;
  }

  table {
    page-break-inside: auto;
  }

  td {
    page-break-inside: avoid;
  }

  tr {
    page-break-inside: auto;
  }

  .bg-table-header {
    background-color: #186418 !important;
    print-color-adjust: exact;
  }

  .bg-td-alt-color {
    background-color: #E2FCE0 !important;
    print-color-adjust: exact;
  }

  .legend-item {
    width: 10px;
    height: 10px;
  }

  .header-text-bold {
    font-weight: bold;
    color: black
  }

  .chart-padding {
    padding-right: 10px !important;
  }

  .table-font-size {
    font-size: 12px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .table-header-padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .print-header {
    display: block;
  }

  .print-padding {
    padding-top: 20px;
  }

  .print-page-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .print-header-img {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-font {
    font-size: 25px !important;
    font-weight: bold !important;
  }

  .print-center {
    text-align: center;
    margin-top: 22% !important;
  }

  .chart-position {
    display: block;
    margin-top: 100px;
  }

}

.st-theme-default{
  width: 100%;
}

.nav-link  .active {
  color: green !important;
}

.st-theme-default > .nav .nav-link.active {
  color: #253525 !important;
}